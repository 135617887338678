

























































































































































































































































import { computed, defineComponent, ref, watch } from '@vue/composition-api';
import myAttributes from '@/composition/myAttributes';
import { useSchedule } from '@/composition/schedule';
import { useNotification } from '@/composition/notification';
import { useConfirm } from '@/composition/confirm';
import { useWysiwygSetting } from '@/composition/wysiwygSetting';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcDate from '@/components/FcDate.vue';
import FcWysiwyg from '@/components/FcWysiwyg.vue';
import FcImage from '@/components/FcImage.vue';
import FcStaticImage from '@/components/FcStaticImage.vue';
import router from '@/router';
import core from '@/admin/core';

export default defineComponent({
  name: 'Schedule',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    FcDate,
    FcWysiwyg,
    FcImage,
    FcStaticImage,
  },
  props: {
    scheduleEventId: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup(props, context) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('schedules'));
    const { error, notify } = useNotification();
    const { confirmDialog } = useConfirm();
    const schedule = useSchedule(props.scheduleEventId);
    const wysiwygSetting = useWysiwygSetting();
    wysiwygSetting.fetchConsoleMenu();

    // 公開URL
    const publishUrl = computed(() => {
      const publishUrlQuery =
        context.root.$route.query.eventStart && context.root.$route.query.eventEnd
          ? `?start=${context.root.$route.query.eventStart}&end=${context.root.$route.query.eventEnd}`
          : '';
      return schedule.scheduleEventId
        ? `${core.hostingUrl}/#/schedule/${schedule.scheduleEventId}${publishUrlQuery}`
        : '';
    });

    const repeatWeek = new Array(10).fill({}).map((_, index) => {
      return {
        value: index + 1,
        text: `${index + 1}週間`,
      };
    });

    // カレンダーから作成
    watch(
      () => schedule.isLoading,
      () => {
        if (!schedule.isLoading) {
          const startDate = context.root.$route.query.start;
          const endDate = context.root.$route.query.end;
          if (startDate && endDate) {
            schedule.item.startDate.value = Number(startDate);
            schedule.item.endDate.value = Number(endDate);
            schedule.item.isAllDay = true;
          }
        }
      }
    );

    // 保存
    const isSaving = ref(false);
    const save = async () => {
      if (!(await confirmDialog('本当に保存しますか？'))) return;
      isSaving.value = true;
      try {
        if (props.scheduleEventId) {
          await schedule.saveSchedule();
          notify('保存しました。');
        } else {
          await schedule.createSchedule();
          notify('作成しました。');
          router.push('/schedules');
        }
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };

    // 削除
    const deleteSchedule = async () => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      isSaving.value = true;
      try {
        await schedule.deleteSchedule();
        notify('削除しました。');
        context.root.$router.push('/schedules');
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };

    // 複製
    const copySchedule = async () => {
      if (!(await confirmDialog('複製しますか？'))) return;
      isSaving.value = true;
      try {
        await schedule.copySchedule();
        notify('複製しました。');
        context.root.$router.push(`/schedule/${schedule.scheduleEventId}`);
      } catch (e) {
        error(e);
      } finally {
        isSaving.value = false;
      }
    };

    // 繰り返し設定の曜日の選択可否切り替え（期間24h以上の繰り返しイベント作成時は曜日を固定）
    const isSelectableDay = ref(true);
    watch(
      () => [schedule.item.startDate, schedule.item.endDate, schedule.item.isRepeat],
      () => {
        if (!schedule.item.startDate.value || !schedule.item.endDate.value || !schedule.item.isRepeat)
          return (isSelectableDay.value = true);

        const diff = schedule.item.endDate.value - schedule.item.startDate.value;
        if (diff < 24 * 60 * 60 * 1000) return (isSelectableDay.value = true);

        isSelectableDay.value = false;
        schedule.item.repeatDay = [new Date(schedule.item.startDate.value).getDay()];
      }
    );

    const copyPublishUrl = () => {
      if (!publishUrl.value) return;
      navigator.clipboard
        .writeText(publishUrl.value)
        .then(() => notify('コピーしました。'))
        .catch(() => error('コピーできませんでした。'));
    };

    return {
      pageTitle: 'スケジュール',
      myRoleSettings,
      wysiwygSetting,
      publishUrl,
      repeatWeek,
      schedule,
      isSaving,
      isSelectableDay,
      save,
      deleteSchedule,
      copySchedule,
      copyPublishUrl,
    };
  },
});
